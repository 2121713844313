@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
#styleguide

```

```
*/


/* 下層テンプレート */
.modContentsContainer {
  padding-bottom: 50px;
}

.modContents {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 38px;
    margin-bottom: 20px;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 38px;
    margin-bottom: 20px;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignleft,
  .alignright {
    @include mixin.tabletP {
      display: block;
      float: none;
      margin: 0 auto 10px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: none;
    font-weight: 700;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    &:before,
    &::after {
      content: "";
      display: table;
      table-layout: fixed;
      clear: both;
    }
  }

  h2 {
    border-bottom: 1px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_MAIN;
    font-size: calc(26px + 6 * (100vw - 320px) / 1600);
    padding-bottom: 10px;
  }

  h3 {
    border-left: 5px variables.$COLOR_MAIN solid;
    font-size: calc(24px + 4 * (100vw - 320px) / 1600);
    padding-left: 26px;

    @include mixin.tabletP {
      padding-left: 18px;
    }
  }

  h4 {
    font-size: calc(20px + 4 * (100vw - 320px) / 1600);
    padding-left: 20px;
    margin-bottom: 15px;
    position: relative;

    &::before {
      background: url(#{mixin.set_common_path('icon_ttl_ja.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  h5 {
    color: variables.$COLOR_MAIN;
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 40px;
  }

  ul:not(.list_1):not(.list_1 li ul):not(.wp-pagenavi):not(.modListJump):not(.modList):not(.modTagList) {
    margin-bottom: 50px;
    list-style: none;

    li {
      font-weight: 700;
      padding-left: 20px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      &::before {
        border-style: solid;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #0556cc;
        border-right: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol_li;
    padding-left: 30;
    margin-bottom: 50px;

    li {
      font-weight: 700;
      padding-left: 30px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      &::before {
        border: 1px variables.$COLOR_MAIN solid;
        counter-increment: ol_li;
        content: "" counter(ol_li);
        color: variables.$COLOR_MAIN;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        line-height: normal;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 6px;
        left: 0;
      }

      p {
        margin-bottom: 0;
      }

      ol {
        margin-bottom: 0;

        li {
          &::before {
            background-color: variables.$COLOR_MAIN;
            border: 1px #fff solid;
            counter-increment: ol_li;
            content: "" counter(ol_li);
            color: #fff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-weight: 500;
            line-height: normal;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            top: 6px;
            left: 0;
          }
        }
      }
    }
  }

  .wp-caption,
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  .wp-caption {
    p {
      margin: 0;
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-break: anywhere;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {}

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {}

  iframe {
    width: 100%;
    max-width: 100%;
  }

  a:not([class^="btn"]):not(.wp-pagenavi a):not(.modListJump a):not(.list_1 a) {
    color: variables.$COLOR_MAIN;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: variables.$COLOR_MAIN;
    }
  }

  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt {
    display: inline-block;
    font-weight: 700;
    padding-right: 45px;
    margin-right: 5px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn_pdf {
    background: url(#{mixin.set_common_path('bg_pdf.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_doc {
    background: url(#{mixin.set_common_path('bg_doc.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_xls {
    background: url(#{mixin.set_common_path('bg_xls.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_ppt {
    background: url(#{mixin.set_common_path('bg_ppt.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_link {
    background: url(#{mixin.set_common_path('bg_blank.svg')}) no-repeat right center;
    background-size: auto;
    font-weight: 700;
    display: inline-block;
    padding-right: 24px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn01 {
    background-color: transparent;
    border: 1px map_get(variables.$COLOR, gray) solid;
    border-radius: 26px;
    color: map_get(variables.$COLOR, gray);
    display: inline-block;
    font-weight: 400;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    line-height: normal;
    min-width: 220px;
    max-width: 100%;

    &:hover {
      border-color: variables.$COLOR_MAIN;
      color: variables.$COLOR_MAIN;
      text-decoration: none;
      opacity: 1;
    }

    +[class^="btn0"] {
      margin-left: 50px;

      @include mixin.tabletP {
        margin-left: 30px;
      }
    }
  }

  .btn02 {
    color: map_get(variables.$COLOR, gray);
    padding-right: 40px;
    text-decoration: none;
    position: relative;

    &::after {
      background: url(#{mixin.set_common_path('icon_link_gray_sm.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 28px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &:hover {
      color: variables.$COLOR_MAIN;
      opacity: 1;

      &::after {
        background: url(#{mixin.set_common_path('icon_link_blue_sm.svg')}) no-repeat center center;
      }
    }
  }

  table:not(.tbl_faq):not(.modkFormTbl) {
    border-collapse: collapse;
    border-spacing: 0;
    border-top: 1px map_get(variables.$COLOR, gray) solid;
    display: table;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;

    th,
    td {
      background-color: transparent;
      border-bottom: 1px map_get(variables.$COLOR, gray) solid;
      padding: 20px 15px;
      vertical-align: top;

      @include mixin.tabletP {
        padding: 15px 10px;
      }
    }

    th {
      font-weight: 700;
      width: 30%;
    }

    td {
      width: 70%;
    }

    .wp-caption {
      p {
        margin: 0;
      }

      img {
        margin: 0 auto;
        padding: 0;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &.tbl_none {
      border: none;
      display: table;
      table-layout: fixed;

      th,
      td {
        border: none;
        margin: 0;
        padding: 0;

        p {
          font-weight: normal;
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      table-layout: fixed;

      th,
      td {
        @include mixin.tabletP {
          display: block;
          width: 100% !important;
        }
      }

      th {

        @include mixin.tabletP {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {

        @include mixin.tabletP {
          padding-top: 0;
        }
      }
    }

    &.tbl_responsive02 {
      th,
      td {

        @include mixin.tabletP {
          display: inline-block;
          width: 50% !important;
        }
      }
    }

  }
}

.tbl_faq {
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;

  &.last {
    margin-bottom: 50px;
  }

  +.tbl_faq {
    margin-top: 30px;
  }

  th,
  td {
    border: none;
    display: block;
    width: 100%;
  }

  th {
    background-color: white !important;
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    padding: 15px 0;
    padding-left: 60px;
    position: relative;

    &::before {
      background-color: variables.$COLOR_MAIN;
      color: white;
      content: "Q";
      font-family: variables.$FONT_ALPHA;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  td {
    background-color: rgba(5, 86, 204, .1);
    padding: 30px;

    @include mixin.tabletP {
      padding: 20px;
    }
  }
}

.toggle_panel {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  max-width: 100%;

  +.toggle_panel,
  +.modCard {
    margin-top: 15px;
  }

  &.last-item {
    margin-bottom: 50px;
  }

  .accordion_title {
    font-size: calc(18px + 6 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: 1;
    padding: 30px;
    padding-left: 55px;
    position: relative;

    @include mixin.tabletP {
      padding: 20px 15px;
      padding-left: 37px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      background: url("#{mixin.set_common_path('icon_ttl_ja.svg')}") no-repeat center center;
      content: "";
      display: block;
      width: 17px;
      height: 17px;
      left: 30px;

      @include mixin.tabletP {
        left: 15px;
      }
    }

    &:after {
      background: url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 30px;
      height: 30px;
      right: 30px;
      transform: translateY(-50%) rotate(0deg);
      transition-duration: 0.3s;

      @include mixin.tabletP {
        width: 18px;
        height: 18px;
        right: 15px;
      }
    }

    &.toggle_open {
      &:after {
        background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
        background-size: 100% auto;
        transform: translateY(-50%) rotate(180deg);
        transition-duration: 0.3s;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }

  .accordion_body {
    display: none;
    padding: 0 30px 20px;
    position: relative;

    @include mixin.tabletP {
      padding: 0 15px 20px;
    }

    h5 {
      margin-bottom: 0 !important;
    }

    p {
      margin-bottom: 30px;
    }

    .tag {
      background-color: map_get(variables.$COLOR, gray);
      color: white;
      font-size: 12px;
      line-height: normal;
      padding: 2px 10px 3px;

      +p {
        margin-bottom: 10px;
      }
    }
  }
}

.flow-unit {
  counter-reset: flow_num;

  .card {
    flex-direction: row;
    padding-bottom: 20px;
    min-height: 200px;
    position: relative;

    @include mixin.tabletP {
      padding-bottom: 13px;
    }

    &::after {
      border-style: solid;
      border-right: 40px solid transparent;
      border-left: 40px solid transparent;
      border-top: 22px solid #0556cc;
      border-bottom: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      @include mixin.tabletP {
        border-right: 25px solid transparent;
        border-left: 25px solid transparent;
        border-top: 14px solid #0556cc;
      }
    }

    +.card {
      margin-top: 30px;
    }

    &:last-child {
      margin-bottom: 50px;

      &::after {
        display: none;
      }
    }
  }

  .card-header {
    background-color: variables.$COLOR_MAIN;
    border-radius: 0;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: variables.$FONT_ALPHA;
    font-weight: 700;
    line-height: normal;
    width: 70px;
    position: relative;

    &::after {
      background-color: variables.$COLOR_MAIN;
      color: white;
      counter-increment: flow_num;
      content: "0" counter(flow_num);
      display: block;
    }
  }

  .card-body {
    background-color: rgba(5, 86, 204, .1);
    width: calc(100% - 70px);
    padding: 30px;

    @include mixin.tabletP {
      padding: 20px 15px;
    }
  }

  .card-title {
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    margin-bottom: 10px;
    line-height: normal;
  }
}

.list_1 {
  box-sizing: border-box;
  display: flex;
  gap: 40px 50px;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 50px;
  width: 100%;

  @include mixin.tabletL {
    gap: 30px;
  }

  @include mixin.tabletP {
    gap: 30px 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      &::before {
        text-decoration: none;
      }
    }
  }

  >li {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4 - 38px);

    @include mixin.tabletL {
      width: calc(100% / 2 - 15px);
    }

    @include mixin.tabletP {
      width: 100%;
    }

    > a {
      border-bottom: 1px variables.$COLOR_MAIN solid;
      color: variables.$COLOR_MAIN;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      line-height: 1.6;
      padding-bottom: 10px;
    }

    >ul {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 10px 0;
      margin-top: 15px;

      >li {
        border: none;
        display: block;

        &::before {
          display: none;
        }

        a {
          color: variables.$COLOR_TEXT;
          font-weight: 400;
          line-height: 1.8;

          &::before {
            content: "-";
            display: inline-block;
            padding-right: 10px;
          }

          &::after {
            display: none;
          }

          &:hover {

            &::before {
              text-decoration: none;
            }
          }

          &.ac-toggle {
            &::before,
            &::after {
              border: none;
              background: variables.$COLOR_MAIN;
              content: "";
              display: block;
              width: 12px;
              height: 2px;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translate(0, -50%) rotate(0deg);
              transition: all .3s ease;
            }

            &::after {
              transform: translate(0, -50%) rotate(90deg);
            }

            &.is-open {
              &::after {
                transform: translate(0, -50%) rotate(0deg);
              }
            }
          }
        }

        >.acod-panel {
          display: none;
          margin-left: 40px;

          @include mixin.tabletP {
            margin-left: 30px;
          }

          li {

            a {
              font-weight: 400;
              padding: 10px;
              position: relative;

              &::before {
                content: "-";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.accordion {
  padding-left: 5px !important;
  position: relative;

  .ac-toggle {
    display: block;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: black;
      position: absolute;
      top: 50%;
      width: 18px;
      height: 1px;
      left: 0;
      transition: all 0.3s;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &::after {
      transform: translateX(-50%);
    }

    &.open {
      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        opacity: 0;
      }
    }
    span {
      display: block;
      padding-left: 20px;
    }
  }

  .content {
    display: none;
  }
}

.size-full {
  @include mixin.tabletP {
    margin: 0 auto 10px;
    width: 100%;
  }
}

.scroll_wrap {

  @include mixin.tabletP {
    margin-bottom: 30px;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;

    &:before {
      content: "→スクロールできます。";
      display: inline-block;
      font-weight: 700;
    }


    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f0f0f0;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .3);
      border-radius: 5px;
    }
  }
}

.tbl_scroll {

  @include mixin.tabletP {
    margin-bottom: 0;
    max-width: 767px !important;
    width: 767px !important;
  }
}
