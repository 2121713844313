@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modParts
*/

.bg__blue {
  background-color: rgba(5, 86, 204, .1);
}

.modRatio {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
  }

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}

.myBlockAnime {
  max-height: 90vh;
  width: 700px;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__blob {
    filter: blur(3px);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;

    @include mixin.tabletP {
      width: 300px;
      height: 300px;
    }

    path {
      box-shadow: 0px 0px 20px 5px rgba(9, 44, 123, 1);
    }

    &.-left {
      transform: translate(calc(-50% - 90px), -50%);

      @include mixin.tabletP {
        transform: translate(calc(-50% - 60px), -50%);
      }

      path {
        animation: blob-left 10s linear infinite, rot-left 60s linear infinite;
      }
    }

    &.-right {
      transform: translate(calc(-50% + 90px), -50%);

      @include mixin.tabletP {
        transform: translate(calc(-50% + 60px), -50%);
      }

      path {
        animation: blob-right 8s linear infinite, rot-right 70s linear infinite;
      }
    }
  }
}

@keyframes blob-left {
  0% {
    d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
  }

  25% {
    d: path("M67.8,-97.1C87.8,-78.8,103.8,-58.9,117.4,-34.1C130.9,-9.4,142,20.2,139.5,50.7C137,81.2,120.8,112.6,95.3,150.1C69.8,187.7,34.9,231.3,3.3,226.8C-28.2,222.2,-56.4,169.3,-91.6,134.9C-126.8,100.5,-169,84.6,-179.6,57.1C-190.2,29.7,-169.3,-9.3,-155.2,-49.7C-141,-90.1,-133.7,-132,-109,-148.8C-84.2,-165.6,-42.1,-157.3,-9.1,-144.8C23.9,-132.2,47.8,-115.5,67.8,-97.1Z");
  }

  50% {
    d: path("M137.1,-191.3C172,-163.4,190.6,-115.7,197.2,-70.1C203.8,-24.4,198.5,19.2,178.9,51.5C159.3,83.9,125.5,105,93.3,129.6C61.1,154.1,30.6,182.1,1.1,180.6C-28.4,179.1,-56.8,148.2,-81.2,121.1C-105.6,94.1,-126.1,70.8,-141.6,41.6C-157.2,12.4,-168,-22.9,-153.9,-45C-139.8,-67,-100.7,-76,-70.9,-105.5C-41.1,-135,-20.6,-185,15.3,-206C51.1,-227.1,102.3,-219.1,137.1,-191.3Z");
  }

  75% {
    d: path("M123.7,-157.1C162.4,-142.2,197.2,-108.8,202.8,-70.8C208.3,-32.9,184.5,9.7,169,54.2C153.6,98.7,146.4,145.2,119.7,162.7C92.9,180.2,46.4,168.6,-1.9,171.1C-50.2,173.7,-100.3,190.4,-122.2,171.3C-144.1,152.3,-137.7,97.5,-144.1,52.7C-150.6,7.9,-169.9,-26.8,-170.5,-64.8C-171,-102.8,-152.8,-144,-121.3,-161.3C-89.7,-178.5,-44.9,-171.8,-1.2,-170.1C42.5,-168.5,85,-172,123.7,-157.1Z");
  }

  100% {
    d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
  }
}

@keyframes blob-right {
  0% {d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
  }

  25% {
    d: path("M67.8,-97.1C87.8,-78.8,103.8,-58.9,117.4,-34.1C130.9,-9.4,142,20.2,139.5,50.7C137,81.2,120.8,112.6,95.3,150.1C69.8,187.7,34.9,231.3,3.3,226.8C-28.2,222.2,-56.4,169.3,-91.6,134.9C-126.8,100.5,-169,84.6,-179.6,57.1C-190.2,29.7,-169.3,-9.3,-155.2,-49.7C-141,-90.1,-133.7,-132,-109,-148.8C-84.2,-165.6,-42.1,-157.3,-9.1,-144.8C23.9,-132.2,47.8,-115.5,67.8,-97.1Z");
  }

  50% {
    d: path("M137.1,-191.3C172,-163.4,190.6,-115.7,197.2,-70.1C203.8,-24.4,198.5,19.2,178.9,51.5C159.3,83.9,125.5,105,93.3,129.6C61.1,154.1,30.6,182.1,1.1,180.6C-28.4,179.1,-56.8,148.2,-81.2,121.1C-105.6,94.1,-126.1,70.8,-141.6,41.6C-157.2,12.4,-168,-22.9,-153.9,-45C-139.8,-67,-100.7,-76,-70.9,-105.5C-41.1,-135,-20.6,-185,15.3,-206C51.1,-227.1,102.3,-219.1,137.1,-191.3Z");
  }

  75% {
    d: path("M123.7,-157.1C162.4,-142.2,197.2,-108.8,202.8,-70.8C208.3,-32.9,184.5,9.7,169,54.2C153.6,98.7,146.4,145.2,119.7,162.7C92.9,180.2,46.4,168.6,-1.9,171.1C-50.2,173.7,-100.3,190.4,-122.2,171.3C-144.1,152.3,-137.7,97.5,-144.1,52.7C-150.6,7.9,-169.9,-26.8,-170.5,-64.8C-171,-102.8,-152.8,-144,-121.3,-161.3C-89.7,-178.5,-44.9,-171.8,-1.2,-170.1C42.5,-168.5,85,-172,123.7,-157.1Z");
  }

  100% {
    d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
  }

}

@keyframes rot-left {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rot-right {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

/*
#styleguide
.modParts__card

```
<div class="card modParts__card">
<div class="card-img modParts__card--img">
    <img src="<%- set_image_path('answer/answer_img01.svg') %>" alt="" loading="lazy">
  </div>
  <div class="card-body modParts__card--body">
    <p class="card-title modParts__card--ttl">タイトル</p>
    <p class="card-title modParts__card--txt">この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。</p>
  </div>
  <div class="card-footer modParts__card--footer">
    <a href="" class="modBtn01"></a>
  </div>
</div>
```
*/
a.modParts__card {
  color: variables.$COLOR_TEXT;
  text-decoration: none;

  &:hover {
    color: variables.$COLOR_TEXT;
  }
}

.modParts__card {

  &--img {
    text-align: center;

    img {
      width: auto;

      @media (min-width:768px) and (max-height: 900px) {
        height: 45vh;
      }

    }
  }

  &--body {
    padding-bottom: 40px;
  }

  &--footer {
    text-align: center;
  }

  &--ttl {
    font-size: calc(20px + 8 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
    text-align: center;

  }

  &--txt {

    span {
      color: map_get(variables.$COLOR, gray);
      display: block;
      font-size: 14px;
    }
  }

  &:hover {
    .modBtn01 {
      border-color: variables.$COLOR_MAIN;

        &::after {
          background: url(#{mixin.set_common_path('icon_arrow_sm_blue.svg')
        }) no-repeat center center;
        background-size: 100% auto;
      }
    }
  }

}

/*
#styleguide
.modFlex

```
<ul class="modFlex d-flex">
  <li class="modFlex__item">
    <a href="###" class="">
      <img src="<%- set_common_path('bnr_img1.jpg') %>" alt="" loading="lazy">
    </a>
  </li>
  <li class="modFlex__item">
    <a href="###" class="">
      <img src="<%- set_common_path('bnr_img2.jpg') %>" alt="" loading="lazy">
    </a>
  </li>
</ul>
```
*/
.modFlex {
  gap: 0 20px;

  @include mixin.tabletP {
    flex-wrap: wrap;
    gap: 20px 0;
  }

  &__item {
    text-align: center;
    width: calc(100% / 2);

    @include mixin.tabletP {
      width: 100%;
    }
  }
}

.myBlockScroll {
  // position: sticky;
  top: 0;
  overflow-x: visible;

  &__container {
    width: 100%;
    position: relative;
  }

  &__wrap {
    // margin-left: calc(50% + 50vw);
    margin-left: 100vw;
    display: flex;
    gap: 0 60px;
    width: calc(700px * 3 + 60px*2 + 100vw);
    // width: 300vw;
    // max-width: calc((860px + 100px * 2) * 3);

    @include mixin.tabletL {
      gap: 0 30px;
      // width:200vw;
      width: calc(61vw * 3 + 30px*2 + 100vw);
    }

    &--item {
      width: 700px;
      // max-width: calc(860px + 100px * 2);
      @include mixin.tabletL {
        width: 61vw;
      }
    }
  }
}

/*
#styleguide
.updateBlock .modUpdate

```
<div class="updateBlock">
  <time class="modUpdate" datetime="2023-12-01">2023.00.00</time>
</div>
```
*/

.updateBlock {
  margin-bottom: 20px;

  .modUpdate {
    color: map_get(variables.$COLOR, gray);
    font-family: variables.$FONT_ALPHA;
  }
}

.modImgBlock {
  margin-bottom: 20px;
}



