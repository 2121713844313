@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modNav
*/

/*
#styleguide

```

```
*/

/* パンくずリスト */
.myBlockBreadcrumb {
	padding: 0 16px;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}

.breadcrumb__list {

}

.breadcrumb {
	&.pankuzu {
		background-color: transparent;
		padding: 0;
		margin-bottom: 0;
		align-items: center;
	}

  &-item {
    padding-left: 0;
    position: relative;

    +.breadcrumb-item {
      padding-left: 0;
      position: relative;

      &::before {
        content: "/";
        display: inline-block;
        line-height: inherit;
        padding: 0 10px;
      }
    }

    a {
      color: map_get(variables.$COLOR, gray);
      text-decoration: none;
    }

    &.active {
      color: variables.$COLOR_TEXT;
      padding-right: 0;
    }
  }
}

/* ページネーション */
.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  margin: 0 auto 50px;
  width: 100%;

  @include mixin.tabletP {
    gap: 0 5px;
  }

  a,
  span {
    background-color: transparent;
    border-radius: 100%;
    color: variables.$COLOR_TEXT;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: variables.$FONT_ALPHA;
    font-size: calc(16px + 8 * (100vw - 320px) / 1600);
    font-weight: 700;
    height: 40px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    padding: 0;

    @include mixin.tabletP {
      height: 30px;
      width: 30px;
    }

    &:hover {
      background-color: variables.$COLOR_MAIN;
      color: white;
      text-decoration: none;
    }
  }

  .previouspostslink,
  .nextpostslink {

    &:hover {
      background-color: transparent;
      color: variables.$COLOR_MAIN;
    }
  }

  span {
    &.current {
      background-color: variables.$COLOR_MAIN;
      color: white;
    }
  }
}


