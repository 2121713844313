@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
other
*/


/* Emphasis character / 強調文字 */
.em-600 {
  font-weight: 600
}

.em-700 {
  font-weight: 700
}

.em-800 {
  font-weight: 800
}

.em-900 {
  font-weight: 900
}

/* ==[ 画像切り取り ]==================================== */
.object-fit-img {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

/* ==[ スライダー共通 ]==================================== */
/*
#styleguide
swiper
```
<div class="swiper-container slider-mv">
  <!-- メイン表示部分 -->
  <div class="swiper-wrapper">
  < !-- 各スライド -->
  <div class="swiper-slide">Slide 1</div>
  <div class="swiper-slide">Slide 2</div>
  <div class="swiper-slide">Slide 3</div>
  <div class="swiper-slide">Slide 4</div>
  </div>
  <!-- 前ページボタン -->
  <div class="swiper-button-prev"></div>
  <!-- 次ページボタン -->
  <div class="swiper-button-next"></div>
</div>
```
*/
.swiper-container {
  overflow: hidden;
}

.swiper-slide {
  img {
    width: 100%;
    max-width: 100%;
  }
}

.swiper-prev,
.swiper-next {
  opacity: 1;
  z-index: 1;
}

.swiper-prev {
  left: 0;

  &::before {
    content: "";
  }
}

.swiper-next {
  right: 0;

  &::before {
    content: "";
  }
}

@keyframes rotateLoading {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.6);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}


/*↓消さないこと*/
.modLoading {
	background-color:rgba(255,255,255,0.8);
	bottom:0;
	left:0;
	position:fixed;
	right:0;
	top:0;
	&__inner{
		bottom: 0;
		height: 42px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top:0;
		width: 42px;
		&::after {
			@include mixin.animation(rotateLoading,0.75s,linear,infinite);
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			background: transparent !important;
			background-color: #666;
			border: 2px solid #666;
			border-bottom-color: transparent;
			border-radius: 100%;
			content: '';
			display: inline-block;
			height: 40px;
			margin: 2px;
			width: 40px;
		}
	}


}
/*↑消さないこと*/

/*↓消さないこと*/
.c-loading {
  background-color: rgba(255 255 255 / 80%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.c-loading-inner {
  @include mixin.animation(rotateLoading, 0.75s, linear, infinite);
  animation-fill-mode: both;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 -10px 0 hsl(0, 0%, 0%), 7px -7px 0 hsl(0, 0%, 93%), 10px 0 0 hsl(0, 0%, 87%), 7px 7px 0 hsl(0, 0%, 80%),
    0 10px 0 hsl(0, 0%, 73%), -7px 7px 0 hsl(0, 0%, 67%), -10px 0 0 hsl(0, 0%, 60%), -7px -7px 0 hsl(0, 0%, 40%);
  height: 5px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
}
