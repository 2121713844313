@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modTable
*/

/*
#styleguide
.modTable01

```
```
*/

.modkFormTbl {
  border: none;
  display: table;
  table-layout: fixed;

  th,
  td {
    border: none;
    background-color: transparent;
  }

  th {
    font-weight: 700;
    padding-bottom: 10px;
  }

  td {
    display: block;
    padding-bottom: 20px;
    width: 100%;
  }

  .input-wrap {

    +.input-wrap {

      @include mixin.tabletP {
        margin-top: 10px;
      }
    }
  }
}
