@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modTitle
*/

/*
#styleguide
.modTitle

```
<div class="modTitle">
  <p class="modTitle__en">Title</p>
  <h2 class="modTitle__jp">タイトルが入ります</h2>
</div>< !-- /.modTitle -->
```
*/

.modTitle {
  margin-bottom: 60px;

  @include mixin.tabletP {
    margin-bottom: 20px;
  }

  &__jp {
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    font-weight: 400;
    line-height: normal;
    padding-left: 22px;
    margin-bottom: 30px;
    position: relative;

    @include mixin.tabletP {
      margin-bottom: 10px;
    }

    &::before {
      background: url("#{mixin.set_common_path('icon_ttl_ja.svg')}") no-repeat center center;
      content: "";
      display: block;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 6px;
      left: 0;
    }

    &.-lg {
      font-size: calc(22px + 10 * (100vw - 320px) / 1600);
      font-weight: 700;
      margin-bottom: 0;
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  &__en {
    color: variables.$COLOR_MAIN;
    font-family: variables.$FONT_ALPHA;
    font-size: calc(55px + 45 * (100vw - 320px) / 1600);
    font-weight: 500;
    letter-spacing: .06em;
    line-height: 1;

    &.-md {
      font-size: calc(42px + 34 * (100vw - 320px) / 1600);
    }

    &.-sm {
      font-size: calc(20px + 4 * (100vw - 320px) / 1600);
      margin-bottom: 30px;

      @include mixin.tabletP {
        margin-bottom: 20px;
      }
    }

    &.-ss {
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    }
  }

  &__main {
    font-size: calc(26px + 6 * (100vw - 320px) / 1600);
    font-weight: 700;
    line-height: normal;
  }

  &__sub {
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    line-height: normal;
  }


}//.modTitle
