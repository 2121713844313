@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

#footer {}
.gFooter {
  background-color: white;
  padding: 100px 0;

  @include mixin.tabletP {
    padding: 50px 0;
  }

  a {
    color: variables.$COLOR_TEXT;
    text-decoration: none;

    &:hover {
      opacity: .3;
    }
  }

  [class^="gFooter__group"] {
    .gFooter__nav {
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;

      &--link {
        li {
          a {
            color: map_get(variables.$COLOR, gray);
            line-height: 1.6;
          }
        }
      }
    }
  }

  &__group01 {
    border-top: 1px map_get(variables.$COLOR, gray) solid;
    border-bottom: 1px map_get(variables.$COLOR, gray) solid;
    padding: 30px 0;

    @include mixin.tabletP {
      border-top: none;
      border-bottom: none;
    }

    .gFooter__nav {
      display: flex;
      gap: 0 20px;

      @include mixin.tabletP {
        display: none;
      }

      &--item {
        width: calc(100% / 5 - 10px);
      }

      &--heading {
        display: block;
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;

        +.gFooter__nav--heading {
          margin-top: 5px;
        }
      }

      &--link {
        display: flex;
        gap: 5px 0;
        flex-direction: column;
        margin-top: 5px;

        li {
          a {
            padding-left: 15px;
            position: relative;

            &::before {
              content: "-";
              display: inline-block;
              text-decoration: none;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .gFooter__sp {
      display: none;
      @include mixin.tabletP {
        display: flex;
        align-items: center;
        gap: 30px 0;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: 80%;
      }
    }
  }

  &__group02 {
    margin-top: 10px;

    .gFooter__nav {
      display: flex;
      gap: 0 60px;

      @include mixin.tabletP {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px 0;
      }

      &--item {
        &:last-child {
          margin-left: auto;

          @include mixin.tabletP {
            margin: 0 auto;
            width: 100%;
          }
        }
      }

      &--link {
        display: flex;
        align-items: center;
        gap: 0 60px;

        @include mixin.tabletP {
          gap: 0 20px;
        }
      }
    }
  }

  &__copyright {
    color: map_get(variables.$COLOR, gray);

    @include mixin.tabletP {
      text-align: center;
    }
  }
}

#pagetop {
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 10;
}
