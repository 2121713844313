@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

.modal-backdrop {
  background-color: rgba(0, 0, 0, .8);

  z-index: 2000;

  &.show {
    opacity: 1;
  }
}

#index .modal-backdrop {
  background: rgb(232, 240, 255);
  background: linear-gradient(-25deg, rgba(232, 240, 255, 1) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 30%, rgba(247, 249, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 80%, rgba(232, 240, 255, 1) 100%);
}

.modal {
  z-index: 3000;

  &.custom-modal {
    background: url(#{mixin.set_common_path('modal_container_bg.png')}) no-repeat center center;
    background-size: 1270px auto;
    padding-right: 0 !important;

    /* &::before {
      background: url(#{mixin.set_common_path('modal_container_bg.png')
      }) no-repeat center center;
      background-size: 1270px auto;
      content: "";
      display: block;
      width: 1270px;
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1050;
    } */

    @include mixin.tabletL {
      background-size: auto 100vh;
    }

    .modal-content {
      background-color: transparent;
      border: none;
    }

    .modal-header {
      border-bottom: none;
      display: block;
      text-align: center;
    }

    .modal-footer {
      border-top: none;
    }

    .modal-closed {
      color: map_get(variables.$COLOR, gray);
      font-family: variables.$FONT_ALPHA;
      text-decoration: none;
    }
  }
}

[aria-hidden="false"] {
  display: block;
  opacity: 1;
}

[aria-hidden="true"] {
  display: none;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.wpcf7-not-valid-tip {
  &[aria-hidden="true"] {
    opacity: 1;
  }
}

.modTxtLeadBlock {
  text-align: center;

  @include mixin.tabletP {
    text-align: left;
  }
}

.modParts__card {
  // padding-bottom: 25vh;

  @include mixin.tabletP {
    padding-bottom: 0;
  }

  &--inner {
    background-color: white;
    padding: 30px;
    width: calc(100% - 60px);
    position: absolute;
    bottom: 0;
    right: 0;

    @include mixin.tabletP {
      padding: 20px;
      width: 100%;
      position: static;
      bottom: auto;
      right: auto;
    }
  }

  &--img {
    overflow: hidden;

    img {
      width: 100%;
      transform: scale(1);
      transition: transform .5s ease;
    }
  }

  &--body {

    @include mixin.tabletP {
      padding-bottom: 10px;
    }
  }

  &--ttl {
    margin-bottom: 40px;

    @include mixin.tabletP {
      margin-bottom: 20px;
    }
  }

  &:hover {
    .modParts__card--img {

      img {
        transform: scale(1.1);
      }
    }
  }
}

.sliderControlBlock {
  display: flex;
  align-items: center;
  gap: 0 30px;
  justify-content: flex-end;
}

.sliderControl {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  margin-top: 0;
  position: static;
  top: auto;
  left: auto;
  right: auto;
  z-index: 1;
  transition: .5 ease;

  &::after {
    content: "";
    display: block;
    width: 50px;
    height: 50px;
  }
}

.swiper-button-prev {

  &::after {
    background: url(#{mixin.set_common_path('slider_prev_off.svg')}) no-repeat center center;
    background-size: 100% auto;
  }

  &:hover {
    &::after {
      background: url(#{mixin.set_common_path('slider_prev_on.svg')}) no-repeat center center;
      background-size: 100% auto;
    }
  }
}

.swiper-button-next {

  &::after {
    background: url(#{mixin.set_common_path('slider_next_off.svg')}) no-repeat center center;
    background-size: 100% auto;
  }

  &:hover {
    &::after {
      background: url(#{mixin.set_common_path('slider_next_on.svg')}) no-repeat center center;
      background-size: 100% auto;
    }
  }
}
