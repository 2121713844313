@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;
@use "sass:math";

#header {}
.gHeader {
  transition: all .5s ease;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;

  &.is-change {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);

    .navbar {
      padding: 10px 58px;

      @include mixin.pc {
        padding: 20px 15px;
      }
    }
  }

  .navbar {
    padding: 20px 58px;
    transition: padding .5s ease;

    @include mixin.pc {
      padding: 20px 15px;
    }

    @include mixin.tabletP {
      background-color: white;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      padding: 0;
    }

    &__inner {
      @include mixin.tabletP {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        width: 100%;
      }
    }
  }

  .navbar-brand {
    padding: 0;

    img {
      width: calc(230 / 1920 * 100vw);
      max-width: 230px;
      min-width: 100px;
    }
  }

  .navbar-nav {
    gap: 0 30px;

    @include mixin.pc {
      gap: 0 20px;
    }

    @include mixin.tabletP {
      padding: 0 15px;
      align-items: flex-start;
    }

    .nav-link {
      padding: 0;

      @include mixin.tabletP {
        padding: 20px;
      }
    }
  }

  .nav-item {

    @include mixin.tabletP {
      border-bottom: 1px white solid;
      width: 100%;

      &:first-child {
        border-top: 1px white solid;
      }
    }

    &.-contact {

      a {
        border: 1px variables.$COLOR_MAIN solid;
        border-radius: 36px;
        color: variables.$COLOR_MAIN;
        padding: 8px 20px;

        @include mixin.tabletP {
          color: white;
          border-radius: 0;
          border: none;
          padding: 20px;
        }

        &:hover {
          background-color: variables.$COLOR_MAIN;
          color: white;

          @include mixin.tabletP {
            background-color: transparent;
          }
        }
      }
    }

    &.dropdown {
      transition: .5s ease;
    }
  }

  .nav-link {
    color: variables.$COLOR_TEXT;
    display: inline-block;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-decoration: none;

    @include mixin.tabletP {
      color: white;
      font-size: 18px;
      text-align: left;
      width: 100%;
    }

    &:hover {
      color: variables.$COLOR_MAIN;
      opacity: 1;

      @include mixin.tabletP {
        color: white;
        opacity: .7;
      }
    }
  }

  .offcanvas-collapse {

    @include mixin.tabletP {
      background-color: #0909D3;
      padding: 0;
      overflow-y: auto;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 100%;
      transition: transform .3s ease-in-out, visibility .3s ease-in-out;

      &.gNavOpen {
        visibility: visible;
        transform: translateX(-100%);
      }
    }
  }

  .collapse {

    &__inner {

      @include mixin.tabletP {
        padding-bottom: 10%;
        height: 100vh;
        overflow-y: scroll;
      }
    }
  }

  /* ナビトグル */
  .navbar-toggler-icon {
    background-image: none;
    background-color: variables.$COLOR_TEXT;
    width: 30px;
    height: 1px;
    display: block;
    position: absolute;
    left: auto;
    right: 0;
    transform: translate(0, 0);
    transition: .3s ease;

    &:nth-of-type(1) {
      top: 10px;
    }

    &:nth-of-type(2) {
      top: 20px;
    }

    &:nth-of-type(3) {
      top: 30px;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .navbar-toggler {
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    padding: 0;
    width: 40px;
    height: 40px;
    position: relative;
    top: auto;
    right: auto;
    z-index: 8000;

    &[aria-expanded="true"] {
      .navbar-toggler-icon {
        background-color: white;

        &:nth-of-type(1) {
          top: 20px;
          transform: translateX(0) rotate(45deg);
        }

        &:nth-of-type(2) {
          transform: scaleX(0);
        }

        &:nth-of-type(3) {
          top: 20px;
          transform: translateX(0) rotate(135deg);
        }
      }
    }

    &.active {
      background-color: #0909d3;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  /* ドロップダウンメニュー */
  .dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    flex-direction: column;
    gap: 15px 0;
    padding: 20px;
    min-width: 15rem;
    transition: all .5s ease;
    transform: translate(0, 0);
    left: 0;

    @include mixin.tabletP {
      background-color: transparent;
      box-shadow: none;
      display: none;
      gap: 10px 0;
      padding: 0;
      padding-left: 30px;
      padding-bottom: 20px;

      &.show {
        display: flex;
      }
    }
  }

  .dropdown-item {
    color: map_get(variables.$COLOR, gray);
    font-size: calc(14px + 2 * (100vw - 320px) / 1600);
    line-height: normal;
    padding: 0;
    padding-left: 15px;
    text-align: left;
    text-decoration: none;
    position: relative;
    white-space: normal;

    @include mixin.tabletP {
      color: white;
      font-style: 16px;
    }

    &::before {
      content: "-";
      display: inline-block;
      text-decoration: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      background-color: transparent;
      opacity: .7;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }

  /* ドロップダウントグル */
  .dropdown-toggle {
    display: inline-block;
    width: 20px;
    height: 20px;

    &[data-toggle="dropdown"] {
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 12px;
      z-index: 10;

      @media screen and (min-width: 768px) {
        display: none;
      }

      &::before,
      &::after {
        background: white;
        border: none;
        content: "";
        display: block;
        width: 18px;
        height: 2px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(0deg);
        transition: .3s ease;

      }
    }

    &[aria-expanded="false"] {
      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &[aria-expanded="true"] {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  /* ドロップダウンホバー処理 */
  .dropdown {
    @media screen and (min-width:768px) {
      &:hover {
        >.dropdown-menu {
          display: flex;
          transition: .5s ease;
        }
      }
    }
  }

  .sp__container {
    display: none;
    padding: 0 15px;
    margin-top: 30px;

    @include mixin.tabletP {
      display: block;
      text-align: center;
    }
  }
}
