@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modBtn01
*/

[class^="modBtn"]:not(.modBtnBlock):not(.modBtn03):not(.modBtnEntry) {
  border: 1px map_get(variables.$COLOR, gray) solid;
  color: map_get(variables.$COLOR, gray);
  text-align: center;
  text-decoration: none;
  position: relative;
  transition: .3s ease;

  &::after {
    background: url(#{mixin.set_common_path('icon_arrow_sm_gray.svg')}) no-repeat center center;
    background-size: 100% auto;
    content: "";
    display: block;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s ease;
  }

  &:hover {
    border-color: variables.$COLOR_MAIN;
    color: variables.$COLOR_MAIN;
  }
}

/*
#styleguide
.modBtn01

```
<a href="" class="modBtn01"></a>
```
*/
.modBtn01 {
  border-radius: 100%;
  width: 30px;
  height: 30px;

  &::after {
    background: url(#{mixin.set_common_path('icon_arrow_sm_gray.svg')}) no-repeat center center;
    width: 18px;
    height: 6px;
    right: -10px;
  }

  &:hover {

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_sm_blue.svg')}) no-repeat center center;
      background-size: 100% auto;
    }
  }
}

/*
#styleguide
.modBtn02

```
<a href="" class="modBtn02"></a>
```
*/
.modBtn02 {
  border-radius: 36px;
  font-family: variables.$FONT_ALPHA;
  letter-spacing: .06em;
  padding: 5px 30px;
  min-width: 180px;

  &::after {
    background: url(#{mixin.set_common_path('icon_arrow_md_gray.svg')}) no-repeat center center;
    width: 30px;
    height: 6px;
    right: -15px;
  }

  &:hover {
    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_md_blue.svg')
      }) no-repeat center center;
      background-size: 100% auto;
    }
  }
}

.modBtn03 {
  border: 1px map_get(variables.$COLOR, gray) solid;
  border-radius: 36px;
  color: map_get(variables.$COLOR, gray);
  text-align: center;
  text-decoration: none;
  padding: 10px;
  min-width: 220px;

  &:hover {
    border-color: map_get(variables.$COLOR, gray);;
    color: map_get(variables.$COLOR, gray);
  }

  &.-black {
    background-color: variables.$COLOR_TEXT;
    border: none;
    color: white;

    &:hover {
      color: white;
    }
  }

  &.-white {
    background-color: white;
    border: none;
    color: map_get(variables.$COLOR, orange);
    padding: 10px;
  }
}

.modBtnEntry {
  background-color: map_get(variables.$COLOR, orange);
  border: 1px map_get(variables.$COLOR, orange) solid;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(18px + 16 * (100vw - 320px) / 1600);
  text-decoration: none;
  min-height: 158px;
  width: 100%;
  position: relative;

  @include mixin.tabletP {
    min-height: 80px;
  }

  &::before {
    background-color: white;
    content: "";
    display: block;
    width: 3px;
    height: 30px;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }


  &:hover {
    background-color: transparent;
    color: map_get(variables.$COLOR, orange);
    opacity: 1;

    &::before {
      background-color: map_get(variables.$COLOR, orange);
    }
  }
}

/* フォーム用ボタン */
/*
#styleguide
.modFormBtn

```
<div class="modFormBtn">
  <input type="submit" value="入力内容確認" class="wpcf7c-btn-confirm">
  <input type="button" value="修正する" class="wpcf7c-btn-back">
  <input type="submit" value="送信" class="wpcf7-submit">
</div>
```
*/
.modFormBtn {
  display: flex;
  align-items: center;
  gap: 20px 15px;
  justify-content: center;
  margin-bottom: 50px;

  @include mixin.tabletP {
    flex-direction: column;
    gap: 15px 0;
  }
}

.wpcf7c-btn-confirm,
.wpcf7c-btn-back,
.wpcf7-submit {
  border: none;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 15px;
  margin: 0;
  height: auto;
  width: 220px;
  max-width: 100%;

  &:hover {
    opacity: .7;
  }
}

[type="submit"] {
  background-color: variables.$COLOR_MAIN;
  color: white;
}

.wpcf7c-btn-back {
  background-color: transparent;
  border: 1px variables.$COLOR_MAIN solid;
  color: variables.$COLOR_MAIN;
}