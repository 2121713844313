@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;
@use "sass:math";

/*
modList
*/

$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';

ul.modListJump {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 70px;
  padding: 0;

  @include mixin.tabletP {
    gap: 10px;
  }

  li {
    display: flex;
    width: calc(100% / 4 - 12px);

    @include mixin.tabletL {
      width: calc(100% / 3 - 10px);
    }

    @include mixin.tabletP {
      width: calc(100% / 2 - 5px);
    }
  }

  a {
    border: 1px variables.$COLOR_MAIN solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: variables.$COLOR_MAIN;
    line-height: normal;
    padding: 10px 15px;
    padding-right: 30px;
    text-decoration: none;
    min-height: 60px;
    width: 100%;
    position: relative;

    &::after {
      border-style: solid;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #0556cc;
      border-bottom: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: variables.$COLOR_MAIN;
    }

  }
}
/*
#styleguide
.myBlockNews__list
```
<div class="modListNews">
  <ul class="myBlockNews__list">
    <li class="myBlockNews__list--item">
      <a href="" class="myBlockNews__list--link">
        <time class="myBlockNews__list--date -en">2023.00.00</time>
        <span class="myBlockNews__list--title">お知らせのタイトルが入ります。お知らせのタイトルが入ります。</span>
      </a>
    </li>
    <li class="myBlockNews__list--item">
      <a href="" class="myBlockNews__list--link">
        <time class="myBlockNews__list--date -en">2023.00.00</time>
        <span class="myBlockNews__list--title">お知らせのタイトルが入ります。お知らせのタイトルが入ります。お知らせのタイトルが入ります。お知らせのタイトルが入ります。</span>
      </a>
    </li>
    <li class="myBlockNews__list--item">
      <a href="" class="myBlockNews__list--link">
        <time class="myBlockNews__list--date -en">2023.00.00</time>
        <span class="myBlockNews__list--title">お知らせのタイトルが入ります。</span>
      </a>
    </li>
  </ul>
</div>
```
*/
.modListNews {

  .myBlockNews__list {

    &--item {
      border-bottom: 1px map_get(variables.$COLOR, gray) solid;

      &:first-child {
        border-top: 1px map_get(variables.$COLOR, gray) solid;
      }
    }

    &--link {
      color: variables.$COLOR_TEXT;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 30px;
      padding-right: 50px;
      position: relative;

      @include mixin.tabletP {
        flex-wrap: wrap;
        padding: 20px;
      }

      &::after {
        background: url(#{mixin.set_common_path('icon_link_gray.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 39px;
        height: 31px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s ease;
        right: 15px;

        @include mixin.tabletP {
          display: none;
        }
      }

      &:hover {
        opacity: 1;

        &::after {
          background: url(#{mixin.set_common_path('icon_link_blue.svg')}) no-repeat center center;
          background-size: 100% auto;

          @include mixin.tabletP {
            display: none;
          }
        }

        .myBlockNews__list--title {
          color: variables.$COLOR_MAIN;
        }
      }
    }

    &--date {
      color: map_get(variables.$COLOR, gray);
      letter-spacing: .06em;
      width: 126px;

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &--title {
      width: calc(100% - 126px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.modFlexBlock {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  &.col1 {
    gap: 20px 0;
    flex-direction: column;

    .modFlexBlock__item {
      width: 100%;

    }
  }

  &.col2 {
    gap: 20px;

    .modFlexBlock__item {
      width: calc(100% / 2 - 10px);

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &.col3 {
    gap: 20px;

    .modFlexBlock__item {
      width: calc(100% / 3 - 10px);

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }


  &.col4 {
    gap: 20px;

    .modFlexBlock__item {
      width: calc(100% / 4 - 5px);

      @media screen and (max-width: 767px) {
        width: calc(100% / 2 - 5px);
      }
    }
  }

  &__item {
    max-width: 100%;
  }
}

.modTagList {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  &__item,
  &__link{
    border: 1px variables.$COLOR_MAIN solid;
    border-radius: 16px;
    color: variables.$COLOR_MAIN;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    padding: 0 10px;
  }

  &__link {
    &:hover {
      background-color: variables.$COLOR_MAIN;
      color: white;
    }
  }
}

/*
#styleguide
.modList
```
<ul class="modList intvw">
  <li>
    <span class="ques">質問が入ります</span>
    <span class="ans">回答が入ります。回答が入ります。回答が入ります。回答が入ります。回答が入ります。</span>
  </li>
</ul>
```
*/
.modList {
  display: flex;

  &.disc {
    flex-direction: column;
    gap: 5px 0;

    li {
      line-height: normal;
      padding-left: 18px;
      position: relative;

      &::before {
        content: "●";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.intvw {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;

    li {
      display: flex;
      flex-direction: column;

      span {
        display: block;

        &.ques {
          font-size: calc(16px + 2 * (100vw - 320px) / 1600);
          font-weight: 700;
          padding-left: 65px;
          position: relative;

          &::before {
            background-color: variables.$COLOR_TEXT;
            content: "";
            display: block;
            width: 56px;
            height: 1px;
            position: absolute;
            top: 18px;
            left: 0;
          }
        }
      }
    }
  }

  &.modLink {
    background-color: white;
    border-radius: 12px;
    padding: 20px 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    li {

      @include mixin.tabletP {
        width: calc(100% / 2 - 5px);
      }
    }

    a {
      background-color: white;
      border: 1px variables.$COLOR_MAIN solid;
      border-radius: 20px;
      color: variables.$COLOR_MAIN;
      line-height: normal;
      padding: 4px 30px;
      text-align: center;
      text-decoration: none;

      @include mixin.tabletL {
        padding: 4px 15px;
      }

      @include mixin.tabletP {
        padding: 4px 8px;
        width: 100%;
      }

      &.current,
      &:hover {
        background-color: variables.$COLOR_MAIN;
        color: white;
      }
    }
  }

  &.anchor {
    counter-reset: anc_num;
    gap: 0 30px;
    margin-bottom: 30px;

    @include mixin.tabletP {
      flex-wrap: wrap;
      gap: 15px 0;
    }

    .anchor__item {
      border: none;
      padding: 24px 0 20px;
      width: calc(100% / 3);
      position: relative;

      @include mixin.tabletP {
        padding: 16px 0 20px;
        width: 100%;
      }

      &::before,
      &::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        color: variables.$COLOR_MAIN;
        counter-increment: anc_num;
        content: "0" counter(anc_num);
        font-family: variables.$FONT_ALPHA;
        font-size: 48px;
        font-weight: 300;
        line-height: 1;
        top: 0;
        z-index: 2;

        @include mixin.tabletP {
          font-size: 36px;
        }
      }

      &::after {
        border-style: solid;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-top: 20px solid #0556cc;
        border-bottom: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        bottom: 0;
      }

      &:hover {
        opacity: .7;
      }
    }

    a {
      background-color: white;
      border: 2px variables.$COLOR_MAIN solid;
      color: variables.$COLOR_MAIN;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(16px + 6 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
      min-height: 126px;
      position: relative;

      @include mixin.tabletP {
        padding: 30px 15px;
        min-height: auto;
      }

      &::before {
        background-color: white;
        content: "";
        display: block;
        height: 3px;
        width: 100px;
        position: absolute;
        top: -2px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }

    }
  }
}

.modDlList {

  dt {
    color: variables.$COLOR_MAIN;
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    line-height: normal;
    margin-bottom: 10px;
  }

  +.modDlList {
    margin-top: 30px;
  }
}